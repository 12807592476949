import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Slide = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  padding: '5px',
  width: '100%',
  height: '100%',
  position: 'relative',
});

export const SlideImg = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: 4,
  borderColor: theme.palette.grey['300'],
}));

export const SlideThumbnailImg = styled('img')({
  display: 'block',
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
  width: '100%',
  height: 'auto',
  position: 'absolute',
  top: 0,
  left: 0,
});
