import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectContentLibraryOpenedFile } from 'features/content-library/contentLibrarySlice';
import SinglePageFile from 'features/content-library/components/file-panel/SinglePageFile';
import GalleryFile from 'features/content-library/components/file-panel/GalleryFile';

export const FilePanel = ({ size }: { size: { width?: number; height?: number } }) => {
  const openedFile = useAppSelector(selectContentLibraryOpenedFile);
  if (!openedFile) {
    return null;
  }

  if ('pages' in openedFile) {
    return <GalleryFile file={openedFile} size={size} />;
  }

  return <SinglePageFile file={openedFile} />;
};
