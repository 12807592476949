import {
  consentConfigReceived,
  loginFailed,
  loginFinished,
  loginStarted,
} from 'features/room/roomSlice';
import i18n from 'i18n';
import { call, fork, put, select, take } from 'redux-saga/effects';
import { AudioPool } from 'utils/webrtc/AudioPool';
import { logEnvSaga } from 'features/application/sagas/logEnvSaga';
import { EnumeratedMediaDevices } from 'features/user-media/types';
import { enumerateDevices } from 'features/user-media/utils';
import { mediaDevicesUpdated } from 'features/user-media/userMediaSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { InitRoomPayload, PrejoinResponse, ValidateRoomResult } from 'features/room/types';
import { validateRoomSaga } from 'features/room/sagas/validateRoomSaga';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { signalingConnected } from 'features/application/actions';
import { brandingReceived } from 'features/branding/brandingSlice';
import { initSentrySaga } from 'features/application/sagas/initSentrySaga';
import { prepareJoinScreen } from 'features/room/sagas/prepareJoinScreen';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { roomErrorSaga } from 'features/room/sagas/roomErrorSaga';
import { enableE2eeSaga } from 'features/e2ee/sagas/enableE2eeSaga';
import { ContentLibraryManager } from 'utils/content-library/ContentLibraryManager';
import { eventBus, SDKInitState } from 'utils/eventBus';
import {
  SDKConnected,
  SDKInitialized,
  selectIsSDKConnected,
  selectSDKInitState,
} from 'features/sdk/sdkStateSlice';
import { RootState, SagaWrappedThunkAction } from 'store/store';
import { RoomLoginFormValues } from 'features/room/components/RoomLogin';
import { AsyncThunk } from '@reduxjs/toolkit/src/createAsyncThunk';

export function* initRoomSaga({ payload: { roomId } }: PayloadAction<InitRoomPayload>) {
  try {
    const { token, settings }: ValidateRoomResult = yield call(validateRoomSaga, roomId);

    // refine SDK status
    yield put(SDKInitialized(eventBus.isEmbed));

    let sdkInitialState: Partial<SDKInitState> = yield select(selectSDKInitState);
    if (eventBus.isEmbed) {
      const sdkConnected: boolean = yield select(selectIsSDKConnected);
      if (!sdkConnected) {
        sdkInitialState = yield take(SDKConnected);
      }
    }

    if (settings.sentryEnabled) {
      yield fork(initSentrySaga);
    }

    if (settings.e2EeEnabled) {
      yield call(enableE2eeSaga);
    }

    yield fork(logEnvSaga);
    yield call(AudioPool.initialize);

    yield fork(ContentLibraryManager.initialize);

    yield put(brandingReceived(settings.branding));

    if (settings.consent) {
      if (settings.consent.type === 'generic') {
        settings.consent.message = i18n.t('join:gdpr.message');
        settings.consent.checkboxMessage = i18n.t('join:gdpr.checkbox_message');
      }

      yield put(consentConfigReceived(settings.consent));
    }

    const isRecorderSession: boolean = yield select(selectIsRecorderSession);
    if (!isRecorderSession) {
      const mediaDevices: EnumeratedMediaDevices = yield call(enumerateDevices);
      yield put(mediaDevicesUpdated(mediaDevices));
    }

    if (token.parsedData?.u || sdkInitialState.username) {
      // no need to show the login form, authorize a user straight away
      yield put(loginStarted());
      const wrappedThunkAction: SagaWrappedThunkAction<
        PrejoinResponse,
        string | undefined,
        {
          state: RootState;
          rejectValue: RoomLoginFormValues;
        }
      > = yield put(authorizeUser());

      const resultAction: AsyncThunk<
        PrejoinResponse,
        string | undefined,
        {
          state: RootState;
          rejectValue: RoomLoginFormValues;
        }
      > = yield take([authorizeUser.fulfilled.type, authorizeUser.rejected.type]);

      if (authorizeUser.rejected.match(resultAction)) {
        if (eventBus.isEmbed) {
          if (resultAction.payload) {
            // at this point it can be only a validation error, display it on the login form
            yield put(loginFailed(resultAction.payload.login));
          } else {
            throw resultAction.error;
          }
        } else {
          yield call(wrappedThunkAction.unwrap);
        }
      } else {
        yield take(signalingConnected);
        yield put(loginFinished());
      }
    }

    yield call(prepareJoinScreen);
  } catch (error) {
    yield call(roomErrorSaga, error);
  }
}
