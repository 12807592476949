import { Box, Chip as MuiChip } from '@mui/material';
import { chipClasses } from '@mui/material/Chip';
import { alpha, styled } from '@mui/material/styles';
import React, { useRef } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectContentLibraryOpenedFileCurrentPage } from 'features/content-library/contentLibrarySlice';
import Thumbnail from 'features/content-library/components/file-panel/thumbnails/Thumbnail';
import ThumbnailControl from 'features/content-library/components/file-panel/thumbnails/ThumbnailControl';

const Chip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== 'handRaised',
})(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: alpha(theme.palette.common.black, 0.7),
  fontSize: theme.typography.pxToRem(14),
  height: 26,
  textTransform: 'capitalize',
  transition: 'none',
  [`& .${chipClasses.labelMedium}`]: {
    padding: theme.spacing(0, 3),
  },
  marginTop: '.75rem',
}));

interface ThumbnailListProps {
  goToPage: (index: number) => void;

  pages: {
    thumbnailUrl: string;
    url: string;
  }[];
}

const ThumbnailList = React.memo(({ pages, goToPage }: ThumbnailListProps) => {
  const currentPage = useAppSelector(selectContentLibraryOpenedFileCurrentPage);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <Box
      ref={ref}
      sx={(theme) => ({
        minWidth: '190px',
        width: '190px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRight: `1px solid ${theme.palette.grey['400']}`,
        height: '100%',
        overflow: 'auto',
        padding: '5px 0',
      })}
    >
      {pages.map((img, idx) => (
        <ThumbnailControl
          className={`slider-thumbnail-${idx}`}
          key={idx}
          onClick={() => goToPage(idx)}
          containerRef={ref}
        >
          <Thumbnail src={img.thumbnailUrl} active={idx === currentPage}>
            <Chip label={idx + 1} />
          </Thumbnail>
        </ThumbnailControl>
      ))}
    </Box>
  );
});

export default ThumbnailList;
