import React, { useState } from 'react';
import { Box, CircularProgress, Skeleton } from '@mui/material';
import { SlideThumbnailImg } from 'features/content-library/components/file-panel/styled';
import { styled } from '@mui/material/styles';

const SkeletonThumbnailOuter = styled(Box)(({ theme }) => ({
  width: '110',
  height: '150',
  background: theme.palette.grey['200'],
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SkeletonThumbnail = () => (
  <SkeletonThumbnailOuter>
    <CircularProgress sx={{ color: 'grey.700' }} disableShrink />
  </SkeletonThumbnailOuter>
);

const height = 150;
const width = 110;

const ImageWrapper = styled('div')({
  position: 'relative',
  width: '110px',
  height: '150px',
  overflow: 'hidden',
  borderRadius: '4px',
  borderWidth: '1px',
  borderStyle: 'solid',
});

const Thumbnail = ({
  src,
  active,
  children,
}: {
  src: string;
  active: boolean;
  children: React.ReactNode;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      <ImageWrapper
        sx={(theme) => ({
          borderColor: active ? theme.palette.primary.main : theme.palette.grey['300'],
          outline: active ? `1px solid ${theme.palette.primary.main}` : 'none',
        })}
      >
        {!isLoaded && <Skeleton variant="rectangular" width={width} height={height} />}
        <SlideThumbnailImg
          src={src}
          alt={`slide-${src}`}
          onLoad={() => setIsLoaded(true)}
          width={width}
          loading="lazy"
          height={height}
        />
      </ImageWrapper>
      {children}
    </>
  );
};

export default Thumbnail;
