import {
  ContentLibraryFile,
  ContentLibraryFileProcessingMeta,
  ContentLibraryFileProcessingStatus,
  ContentLibraryFiles,
  ContentLibraryFolder,
  ContentLibraryFolders,
  ContentLibraryProcessingMeta,
  ContentLibraryReceivedPayload,
  ContentLibraryState,
  FileReceivedPayload,
  FolderReceivedPayload,
} from 'features/content-library/types';

interface NormalizedData {
  folders: ContentLibraryFolders;
  files: ContentLibraryFiles;
  fileProcessingMeta: ContentLibraryProcessingMeta;
}

export const normalizeFolder = (folder: FolderReceivedPayload): ContentLibraryFolder => ({
  id: folder.id,
  name: folder.name,
  parentId: folder.parentId || 'root',
  date: folder.date,
  childFolderIds: [],
  childFileIds: [],
  childUploadIds: [],
});

export const normalizeFile = (file: FileReceivedPayload): ContentLibraryFile => ({
  ...file,
  folderId: file.folderId || 'root',
});

export const createFileProcessingMeta = (
  file: FileReceivedPayload,
  folderId: string
): ContentLibraryFileProcessingMeta => {
  const fileProcessingMeta: ContentLibraryFileProcessingMeta = {
    id: file.id,
    status: file.status as ContentLibraryFileProcessingStatus,
    name: file.name,
    folderId,
    progress: 0,
  };

  return fileProcessingMeta;
};

export const normalizeContentLibrary = (
  state: ContentLibraryState,
  data: ContentLibraryReceivedPayload
) => {
  const normalizedData: NormalizedData = {
    folders: {
      root: {
        id: 'root',
        name: 'Root Directory',
        parentId: '',
        childFolderIds: [],
        childFileIds: [],
        childUploadIds: [],
        date: null,
      },
    },
    files: {},
    fileProcessingMeta: { ...state.fileProcessingMeta },
  };

  for (const folder of data.folders) {
    const normalizedFolder = normalizeFolder(folder);
    // preserve existing upload state
    const existingFolder = state.folders[normalizedFolder.id];
    if (existingFolder?.childUploadIds.length > 0) {
      normalizedFolder.childUploadIds = existingFolder.childUploadIds;
    }

    normalizedData.folders[normalizedFolder.id] = normalizedFolder;

    if (normalizedData.folders[normalizedFolder.parentId]) {
      // Connect to parent folder
      normalizedData.folders[normalizedFolder.parentId].childFolderIds.push(normalizedFolder.id);
    }
  }

  for (const file of data.files) {
    const normalizedFile = normalizeFile(file);
    const parentFolder = normalizedData.folders[normalizedFile.folderId];

    normalizedData.files[normalizedFile.id] = normalizedFile;

    if (file.status !== 'completed') {
      const fileProcessingMeta = createFileProcessingMeta(file, normalizedFile.folderId);
      normalizedData.fileProcessingMeta[fileProcessingMeta.id] = fileProcessingMeta;
      parentFolder?.childUploadIds.push(file.id);
    } else {
      // Remove the existing meta if the file status is 'completed'
      delete normalizedData.fileProcessingMeta[file.id];
      // Clean up childUploadIds if present
      parentFolder.childUploadIds = parentFolder.childUploadIds.filter((id) => id !== file.id);
    }

    // Connect to parent folder
    parentFolder?.childFileIds.push(normalizedFile.id);
  }

  // Preserve existing childUploadIds for each folder
  for (const folderId in state.folders) {
    if (state.folders[folderId].childUploadIds.length > 0) {
      if (normalizedData.folders[folderId]) {
        normalizedData.folders[folderId].childUploadIds = state.folders[folderId].childUploadIds;
      }
    }
  }

  return normalizedData;
};
