import { createAction } from '@reduxjs/toolkit';
import {
  ContentLibraryFileUploadAcceptedPayload,
  ContentLibraryOpenedFile,
  ContentLibraryReceivedPayload,
  CreateContentLibraryFolderPayload,
  DeleteContentLibraryItemPayload,
  EmptyContentLibraryReceivedPayload,
  FileReceivedPayload,
  FolderReceivedPayload,
  RenameContentLibraryItemPayload,
} from 'features/content-library/types';
import { SignalingEventPayload } from 'services/signaling';

export const signalingLibraryReceived = createAction<
  ContentLibraryReceivedPayload | EmptyContentLibraryReceivedPayload
>('signaling/libraryReceived');

export const createContentFolderRequested = createAction<CreateContentLibraryFolderPayload>(
  'contentLibrary/createContentLibraryFolderRequested'
);
export const signalingContentLibraryFolderCreated = createAction<FolderReceivedPayload>(
  'signaling/contentLibraryFolderCreated'
);

export const renameContentFolderRequested = createAction<RenameContentLibraryItemPayload>(
  'contentLibrary/renameFolderRequested'
);
export const signalingContentLibraryFolderRenamed = createAction<RenameContentLibraryItemPayload>(
  'signaling/contentLibraryFolderRenamed'
);

export const deleteContentLibraryFolderRequested = createAction<DeleteContentLibraryItemPayload>(
  'contentLibrary/deleteContentLibraryFolderRequested'
);
export const signalingContentLibraryFolderDeleted = createAction<DeleteContentLibraryItemPayload>(
  'signaling/contentLibraryFolderDeleted'
);

export const renameContentLibraryFileRequested = createAction<RenameContentLibraryItemPayload>(
  'contentLibrary/renameFileRequested'
);
export const signalingContentLibraryFileRenamed = createAction<RenameContentLibraryItemPayload>(
  'signaling/contentLibraryFileRenamed'
);

export const deleteContentLibraryFileRequested = createAction<DeleteContentLibraryItemPayload>(
  'contentLibrary/deleteFileRequested'
);
export const signalingContentLibraryFileDeleted = createAction<DeleteContentLibraryItemPayload>(
  'signaling/contentLibraryFileDeleted'
);

export const cancelContentLibraryFileProcessingRequested =
  createAction<DeleteContentLibraryItemPayload>('contentLibrary/cancelFileProcessingRequested');

// file upload
export const signalingContentLibraryFileUploaded = createAction<FileReceivedPayload>(
  'signaling/contentLibraryFileUploaded'
);
export const signalingContentLibraryFileUploadAccepted =
  createAction<ContentLibraryFileUploadAcceptedPayload>(
    'signaling/contentLibraryFileUploadAccepted'
  );
export const contentLibraryFileUploadRequested = createAction<FileList | null>(
  'contentLibrary/fileUploadRequested'
);

// file conversion
export const signalingContentLibraryFileConverted = createAction<{
  id: string;
}>('signaling/contentLibraryFileConverted');
export const signalingContentLibraryConversionFailed = createAction<{
  id: string;
}>('signaling/contentLibraryFileConversionFailed');
export const signalingContentLibraryConversionProgress = createAction<{
  id: string;
  percentage: number;
}>('signaling/contentLibraryFileConversionProgress');

// open file
export const contentLibraryFileOpenRequested = createAction<{
  id: string;
}>('contentLibrary/fileOpenRequested');
export const signalingContentLibraryFileOpened = createAction<
  SignalingEventPayload<ContentLibraryOpenedFile>
>('signaling/contentLibraryFileOpened');
export const signalingContentLibraryFileClosed = createAction('signaling/contentLibraryFileClosed');
export const contentLibraryChangeFilePageRequested = createAction<{
  id: string;
  page: number;
}>('contentLibrary/fileChangePageRequested');
export const signalingContentLibraryFilePageChanged = createAction<
  SignalingEventPayload<
    ContentLibraryOpenedFile & {
      page: number;
    }
  >
>('signaling/contentLibraryFilePageChanged');
