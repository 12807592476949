import React, { ComponentPropsWithoutRef } from 'react';
import { styled } from '@mui/material/styles';
import { useInView } from 'react-intersection-observer';

const Root = styled('button')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'none',
  padding: 0,
  border: 'none',
  '&:focus': {
    outline: 'none',
  },
});

const Wrapper = styled('div')({
  width: '150px',
  height: '220px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

type ThumbnailControlProps = ComponentPropsWithoutRef<'button'> & {
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
};

const ThumbnailControl = ({ children, containerRef, ...props }: ThumbnailControlProps) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '220px 0px',
    root: containerRef.current,
  });

  return (
    <Root type="button" ref={ref} {...props}>
      <Wrapper>{inView ? children : null}</Wrapper>
    </Root>
  );
};

export default ThumbnailControl;
